<template>
  <s-edit-form
    ref="messageDialog"
    :value="value"
    @input="$emit('input')"
    :api="api"
    :m="m"
    :id="idEdit"
    :configForm="{ type: 'default', hideDetails: true }"
    :init-data="initData"
    :opt="{ closeAfterSave: !true, delete: true, messages }"
  >
    <template v-slot:form>
      <v-row no-gutters style="flex-wrap: nowrap" class="d-flex pb-2">
        <v-switch
          v-model="isInitMessage"
          dense
          :hide-details="true"
          :label="`Сообщение, начинающее диалог`"
          color="indigo"
          class="col-sm-4 col-12"
          @change="$refs.messageDialog.validate('next_id')"
        ></v-switch>
        <v-btn v-if="message && message.from_id" :disabled="$refs.messageDialog.isChanged" class="col-sm-2 col-12" @click="gotoMessage(message.from_id)">
          Пред. ({{ message.from_id }})
        </v-btn>
        <v-btn v-if="message && message.next_id" :disabled="$refs.messageDialog.isChanged" class="col-sm-2 col-12" @click="gotoMessage(message.next_id)">
          След. ({{ message.next_id }})
        </v-btn>
      </v-row>
      <v-row no-gutters style="flex-wrap: nowrap" class="d-flex pb-2">
        <v-select
          class="col-sm-10 col-12"
          v-if="message"
          v-model="message.next_id"
          :items="
            messages
              .filter((el) => el?.id !== message.id)
              .map((m) => {
                return { text: `#${m.id} ${m.content}`, value: m.id };
              })
          "
          item-text="text"
          item-value="value"
          hide-details
          dense
          outlined
          clearable
          label="Следующее сообщение"
          @change="$refs.messageDialog.validate('next_id')"
        >
        </v-select>

        <v-btn
          v-if="message && !message.next_id"
          :disabled1="$refs.messageDialog.isChanged"
          class="col-sm-2 col-12"
          @click="$emit('createMessage', { from_id: message.id })"
        >
          Создать
        </v-btn>
      </v-row>
    </template>
  </s-edit-form>
</template>

<script>
export default {
  props: {
    value: Boolean,
    m: [Object],
    id: Number,
    height: { type: Number, default: 300 },
    initData: { type: Object, default: () => {} },
    data: Object,
    messages: Array,
  },
  data() {
    return {
      showEditDialog: false,
      idEdit: 0,
    };
  },
  watch: {
    value(v) {
      this.idEdit = v;
    },
  },
  computed: {
    message() {
      if (!this.idEdit) return null;
      return this.$refs.messageDialog.data;
    },
    isInitMessage: {
      get() {
        if (this.message) {
          let message = this.message;
          if (message.id == this.data?.education_message_id || message.form_id == -1) return true;
        }
        return false;
      },
      set(v) {
        let message = this.$refs.messageDialog.data;
        if (v) message["from_id"] = -1;
      },
    },
    api() {
      return this.m.api;
    },
    model() {
      let model = this.getModelList(this.m);
      return model;
    },
    canAdd() {
      return true;
    },
  },
  created() {
    this.idEdit = this.id;
  },
  methods: {
    gotoMessage(id) {
      this.showEditDialog = !true;
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>

<style></style>
